
import { defineComponent, reactive, toRefs, computed, onMounted } from "vue";
import Player from "xgplayer";

export default defineComponent({
  name: "xgPlayer",
  props: ["url", "dialogFormVisible"],
  emits: ["close"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      player: null,
    });

    const dialogVisible = computed({
      get() {
        return props.dialogFormVisible;
      },
      set() {
        data.player = null;
        emit("close");
      },
    });

    // watch(
    //   () => props.url,
    //   (newurl) => {
    //     let player = new Player({
    //       id: "vs",
    //       url: newurl,
    //       autoplay: true,
    //     });

    //     //data.player.src = newurl;

    //     data.player = player;
    //     // data.player.reload();
    //     // console.log(data.player);
    //   }
    // );

    const init = () => {
      let player = new Player({
        id: "vs",
        url: props.url,
        autoplay: true,
      });
      data.player = player;
      data.player.getFullscreen(player.root);
      //data.url = url;
    };

    onMounted(() => {
      // data.player = new Player({
      //   id: "vs",
      //   url: "https://lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo.mp4",
      // });
      // data.player.once("complete", () => {
      //   console.log("complete");
      // });
    });

    return {
      ...toRefs(data),
      ...toRefs(props),
      dialogVisible,
      init,
    };
  },
});
