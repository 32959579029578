
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";
import { Api } from "@/assets/Api";
import { ElMessage } from "element-plus";
import xgPlayer from "../xgPlayer.vue";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "ConfigSection",
  components: { xgPlayer },
  setup() {
    let data = reactive<any>({
      init: false,
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: {},
        sort: "menuName",
      },
      palyDialogVisible: false,
      url: "",
    });

    const searchChange = (params: any, done: any) => {
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.User.GetHelpList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.User.GetHelpOption().then((res: any) => {
        data.option = res;
        data.init = true;
      });
    };

    const xgPlayerRef = ref<InstanceType<typeof xgPlayer>>();
    const View = (row: any) => {
      data.url = row.videourl;
      data.palyDialogVisible = true;
      //xgPlayerRef.value?.init(row.videourl);
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      View,
      xgPlayerRef,
    };
  },
});
